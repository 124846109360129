<template>
    <div style="display: flex;justify-content: center;margin-top: 110px">
        <div style="width: 1500px;background: #FFFFFF;min-height: 100vh">
            <div style="color: #666666;float: right;margin: 5px 10px;font-size: 16px">案例金额：<span style="color: #EE3F4D;margin-right: 10px;">{{CaseInfo.money}}.00</span></div>
            <div style="clear: both;margin: 10px;text-align: center;font-size: 20px;color: #444444;font-weight: bold;">{{CaseInfo.title}}</div>
            <div class="follow" style="padding: 10px;margin-right:10px;min-height: 500px;clear: both;width: 500px" v-html="CaseInfo.content"></div>
        </div>
    </div>

</template>

<script>
export default {
    components: {

    },

    computed: {

    },
    created:function(){

    },
    data () {
        return {
            CaseInfo:{}
        }
    },
    beforeCreate() {

    },
    mounted:function(){
        this.getShopCaseInfo()
    },
    methods:{
        // 案例详情
        getShopCaseInfo(){
            var that = this;
            that.newApi.getShopCaseInfo({
                id:that.$route.params.id,
            }).then((res)=>{
                that.CaseInfo = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },

    }
}
</script>
<style scoped>
.follow >>> img {
    max-width: 100%;
}
</style>
